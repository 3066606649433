import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActiveIngredientService } from './active-ingredient.service';
import { IResponse } from '../../../models/response.model'
import { IActiveIngredient } from '../../../models/active-ingredient.model';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DegradationPathwayService } from "../../degradation-pathway/degradation-pathway.service";
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { IRadiolabeledSample } from '../../../models/radiolabeled-sample.model';
import { IStandardSample } from '../../../models/standard-sample.model';
import { CompoundService } from '../../../providers/compound.service';
import { ExportExcel } from '../../../providers/export-excel.service'
import { DropdownFieldService } from '../../../providers/dropdown-field.service'
import { DropDownListService } from '../grid-components/drop-down-list/drop-down-list.service'


export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-active-ingredient-selector',
  templateUrl: './active-ingredient-selector.component.html',
  styleUrls: ['./active-ingredient-selector.component.scss']
})
export class ActiveIngredientSelectorComponent implements OnInit, OnDestroy {

  public imgInfoIcon: string = 'p-button-secondary p-button-outlined p-button-icon mr-2';
  public imgIconLabel: string = 'Active Ingredient Details';
  animal: string;
  name: string;
  FilteredActiveIngredients: IActiveIngredient[] = [];
  public dropdownMode = 'current';
  public nameAC: string = 'N/A';
  public SectionVisible: boolean = false;
  public material_id: number = 0;
  public structure_image: string = '';
  public detail: string = '';
  public img: SafeHtml;

  public rowData: IStandardSample[];
  public columnDefs: (ColDef | ColGroupDef)[] = [];

  public gridApi: GridApi;
  public gridApiSample: GridApi;

  public rowDataRadiolabeledSamples: (IRadiolabeledSample | any)[] = [];
  public columnDefsRadiolabeledSamples: (ColDef | ColGroupDef | any)[] = [];

  //  Description: Active Ingredient Component
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022    
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-25  10/10/2022 -
  //              Description: Call Core Api for Get active ingredient.
  //     </update>
  // </history>
  constructor(public AiService: ActiveIngredientService, public dialog: MatDialog,
    private DegradationPathwayService: DegradationPathwayService,
    private sanitizer: DomSanitizer, private compoundService: CompoundService, private exportExcel: ExportExcel,
    private dropdownFieldService: DropdownFieldService, private dropDownListService: DropDownListService) {
  }

  //  Description: Active Ingredient Component
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  // </history>
  ngOnInit(): void {
  }

  /// <summary>
  //  Description: Degradation Pathway Component Init
  //  Author: Juan Carlos Arce
  //  Creation date: 22/Feb/2023    
  /// </summary>  
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation
  //     </update>
  // </history>
  ngOnDestroy(): void {

  }

  //  Description: Crear Active Ingredient Selection
  //  Author: Juan Carlos Arce
  //  Date: 15/Feb/2023
  //  <history>
  //     <update> H00 - RD-88
  //              Description: Creation.
  //     </update>
  // </history>
  ClearOnClick() {
    this.AiService.SelectedActiveIngredient = null;
  }

  //  Description: Call Core Api for Get active ingredient.
  //  Author: Allam Barrera G
  //  Date: 10/Oct/2022
  //  <history>
  //     <update> H00 - RD-25
  //              Description: Creation.
  //     </update>
  // </history>
  filter(event) {
    this.AiService.search(event.query)
      .pipe(take(1))
      .subscribe((result: IResponse) => {
        this.FilteredActiveIngredients = result.value;
      });
  }

  //  Description: Active Ingredient Component
  //  Author: Juan Carlos Arce
  //  Date: 03/Oct/2022
  //  <history>
  //     <update> H00 - RD-24
  //              Description: Creation.
  //     </update>
  //     <update> H01 - RD-88 - 15/Feb/2023 - Juan Carlos Arce
  //              Description: Remove getActiveIngredient method use and start using SelectedActiveIngredient Propertie
  //     </update>
  //     <update> H02 - RD-108 - 14/Abr/2023 - Reinier Rodriguez V.
  //              Description: Add new section to load new pop up.
  //     </update>
  // </history>
  selectionChanged(activeIngredient) {
    this.AiService.SelectedActiveIngredient = activeIngredient;
    this.dropdownFieldService.dropDownListData = [];
    this.dropDownListService.dropDownListData = [];
  }

  // Ref. H02
  openDialog(): void {
    this.material_id = this.AiService.SelectedActiveIngredient.material_id;
    this.nameAC = this.AiService.SelectedActiveIngredient.compound_name;
    this.detail = '';
    this.img = '';
    this.AiService.showSpinner = true;

    if (this.material_id !== undefined) {
      this.GetSamples();
      this.GetRadiolabeledSamples();
      this.SectionVisible = this.material_id != 0 ? true : false;

      this.DegradationPathwayService.getStructureImage("X" + this.material_id).subscribe((result: any) => {
        this.structure_image = result.structure_image;
        this.img = this.sanitizer.bypassSecurityTrustHtml(this.structure_image);
        this.detail += '<span class="table_subtitle">Molecular Formula:</span> ' + result.molecular_formula + '<br />';
        this.detail += '<span class="table_subtitle">Molecular Weight:</span> ' + result.molecule_weight + '<br />';
        this.detail += '<span class="table_subtitle">Material ID:</span> ' + this.material_id + '<br />';
        this.detail += '<span class="table_subtitle">Smile Code:</span> ' + result.smile_code + '<br />';
        this.detail += '<span class="table_subtitle">Extract Mass:</span> ' + result.extract_mass + '<br />';
        this.detail += '<span class="table_subtitle">Synonyms:</span> ' + result.synonyms + '<br />';
        this.AiService.showSpinner = false;
      });
    }
  }

  //  Description: Call Core Api for Get Samples.
  //  Author: Karina Villalobos S
  //  Date: 09/Jun/2023
  //  <history>
  //     <update> H00 - RD-163
  //              Description: Creation.
  //     </update>
  // </history>
  GetSamples(): void {
    this.material_id = Number(this.material_id.toString().replace("X", ""))
    this.compoundService.GetStandardSample(this.material_id).pipe(take(1))
      .subscribe(data => {
          this.rowData = data;
      });
    this.compoundService.GetInfoStandardSamplesColumsDefs().pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefs = ColDefs;
      });

  }

  //  Description:  Grid Ready to Samples.
  //  Author: Karina Villalobos S
  //  Date: 09/Jun/2023
  //  <history>
  //     <update> H00 - RD-163
  //              Description: Creation.
  //     </update>
  // </history>
  onGridReady(params: GridReadyEvent) {
    this.gridApiSample = params.api;
    this.exportExcel.setDetailInformation(this.gridApiSample, null);
  }
  //  Description: Grid Ready to RadiolabeledSamples.
  //  Author: Karina Villalobos S
  //  Date: 09/Jun/2023
  //  <history>
  //     <update> H00 - RD-163
  //              Description: Creation.
  //     </update>
  // </history>
  onGridReadyRadiolabeledSamples(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.exportExcel.setDetailInformation(this.gridApi, null);
  }
  //  Description: Export Excel to Samples.
  //  Author: Karina Villalobos S
  //  Date: 09/Jun/2023
  //  <history>
  //     <update> H00 - RD-163
  //              Description: Creation.
  //     </update>
  // </history>
  exportToExcelSample(): void {
    this.exportExcel.exportToExcelInfo(this.gridApiSample);
  }

  //  Description: Export Excel to Radiolabeled Samples.
  //  Author: Karina Villalobos S
  //  Date: 09/Jun/2023
  //  <history>
  //     <update> H00 - RD-163
  //              Description: Creation.
  //     </update>
  // </history>
  exportToExcelRadiolabeled(): void {
    this.exportExcel.exportToExcelInfo(this.gridApi);
  }

  //  Description: Call Core Api for Get Radiolabeled Samples.
  //  Author: Karina Villalobos S
  //  Date: 09/Jun/2023
  //  <history>
  //     <update> H00 - RD-163
  //              Description: Creation.
  //     </update>
  // </history>
  GetRadiolabeledSamples(): void {
    this.material_id = Number(this.material_id.toString().replace("X", ""));
    this.compoundService.GetRadioLabeledSamples(this.material_id)
      .pipe(take(1))
      .subscribe(data => {
        this.rowDataRadiolabeledSamples = data;
      });

    this.compoundService.GetInfoRadiolabeledSamplesColumsDefs()
      .pipe(take(1))
      .subscribe(ColDefs => {
        this.columnDefsRadiolabeledSamples = ColDefs;
      });
  }
}
