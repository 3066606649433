import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { settings } from 'src/settings/settings';
import { IIdentity } from '../models/identity.model';
import { IStandardSample } from '../models/standard-sample.model';
import { IResponse } from '../models/response.model';
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class CompoundService {

    constructor(private http: HttpClient) { }

    // <sumary>
    // Description: Get Compound Identidy by Materail ID
    // Author: Juan Carlos Arce
    // Date: 04/Nov/2022
    // </sumary>
    // <history>
    //     <update> H00 - RD-34
    //              Description: Creation
    //     </update>
    // </history>
    GetIdentity(materialId?: string) : Observable<any>
    {
      const query = settings.ServiceUrl + 'api/compound/identity/params?materialId=' + materialId;
      return this.http.get(query);
    }

    // <sumary>
    // Description: Get Compound Standard Sample by Materail ID
    // Author: Juan Carlos Arce
    // Date: 04/Nov/2022
    // </sumary>
    // <history>
    //     <update> H00 - RD-34
    //              Description: Creation
    //     </update>
    //     <update> H01 - Javier Baudrit - RD-98
    //              Description: Changing the service to access Lots information from Alchemist and CoRe
    //     </update>
    // </history>
    GetStandardSample(materialId?: number) : Observable<any>
    {
      const pipe = new DatePipe('en-US');
      const query = settings.ServiceUrl + 'api/compoundstandardsamplescache/external/' + materialId; //Ref. H01
      return this.http.get(query).pipe(map((data: IResponse) => {
        return data.value.map( val => {
          if(val.recertification_date)
            val.recertification_date = pipe.transform( val.recertification_date, 'YYYY-MM-dd');
          return val;
        })
      }));
    }

    GetStandardSamplesColumsDefs() : Observable<any>
    {
      return this.http.get("../../assets/column_defs/StandardSampleColumnsDef.json");
    }
    GetInfoStandardSamplesColumsDefs() : Observable<any>
    {
      return this.http.get("../../assets/column_defs/InfoStandardSampleColumnsDef.json");
    }
    
    // <sumary>
    // Description: Get Compound Names Cache
    // Author: Juan Carlos Arce
    // Date: 17/Nov/2022
    // </sumary>
    // <history>
    //     <update> H00 - RD-54
    //              Description: Creation
    //     </update>
    // </history>
    GetCompoundNamesCache(type: string) : Observable<any>
    {
      const query = settings.ServiceUrl + 'api/compoundnamescache/params?CompoundType=' + type;
      return this.http.get(query);
    }

    search(filter:string) {
      const query = settings.ServiceUrl +'api/compoundnamescache/params?CompoundType=active_ingredient&filter='+ filter;
      return this.http.get(query)
      .pipe(map((response: IResponse) => response.value.map(val => val.compound_name)));
  }

  searchAnalyte(filter:string) {
    const query = settings.ServiceUrl +'api/compoundnamescache/params?filter='+ filter;
    return this.http.get(query)
    .pipe(map((response: IResponse) => response.value.map(val => val.compound_name)));
  }

    // <sumary>
    // Description: Get Compound RadioLabeled Sample by Materail ID
    // Author: Reinier Rodriguez V
    // Date: 09/Feb/2023
    // </sumary>
    // <history>
    //     <update> H00 - RD-52
    //              Description: Creation
    //     </update>
    // </history>
    GetRadioLabeledSamples(materialId?: number) : Observable<any>
    {
      const query = settings.ServiceUrl + 'api/radiolabeledsamples/params?MaterialIdCore=' + materialId;
      return this.http.get(query)
      .pipe(map((response: IResponse) => response.value));
    }

    // <summary>
    //  Description: Get RadiolabeledSamples
    //  Author: Reinier Rodriguez V
    //  Date: 06/Feb/2023
    // </summary>
    // <history>
    //     <update> H00 - RD-52
    //              Description: Creation
    //     </update>
    // </history>
  GetRadiolabeledSamplesColumsDefs() : Observable<any>
  {
    return this.http.get("../../assets/column_defs/RadiolabeledSampleColumnsDef.json");
  }
  GetInfoRadiolabeledSamplesColumsDefs() : Observable<any>
  {
    return this.http.get("../../assets/column_defs/InfoRadiolabeledSampleColumnsDef.json");
  }
}
